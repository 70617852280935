* {
    padding: 0px;
    margin: 0px;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    box-sizing: border-box;
  }

  Body {  
      font-family: Calibri, Helvetica, sans-serif;  
      background-color: #0f2d52e0;
      text-align: center;  
  } 

  .button-login {
      appearance: none;
      background-color: #235f83;
      border: 2px solid #235f83;
      border-radius: 15px;
      box-sizing: border-box;
      color: white;
      cursor: pointer;
      display: inline-block;
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
      margin: 0;
      min-height: 60px;
      min-width: 0;
      outline: none;
      padding: 16px 24px;
      text-align: center;
      text-decoration: none;
      transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      width: 75%;
      will-change: transform;
  }
    
  .button-login:disabled {
      pointer-events: none;
  }
    
  .button-login:hover {
      color: #235f83;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
      transform: translateY(-2px);
  }
    
  .button-login:active {
      box-shadow: none;
      transform: translateY(0);
  }

  .button-Register {
      appearance: none;
      background-color: transparent;
      border: 2px solid #235f83;
      border-radius: 15px;
      color: #235f83;
      cursor: pointer;
      display: inline-block;
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
      height: 32px;
      margin: 0;
      min-height: 60px;
      min-width: 0;
      outline: none;
      padding: 16px 24px;
      text-align: center;
      text-decoration: none;
      transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      width: flex;
      will-change: transform;
  }

  .button-Register:disabled {
      pointer-events: none;
  }

  .button-Register:hover {
      color: #fff;
      background-color: #235f83;
      box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
      transform: translateY(-2px);
  }

  .button-Register:active {
      box-shadow: none;
      transform: translateY(0);
  }

  form {  
     
      display: inline-block;
      margin-left: auto;
      margin-right: auto;   
  }   

  .cancelbtn {   
      width: auto;   
      padding: 10px 18px;  
      margin: 10px 5px;  
  }   
          
      
  .login-container {
      background: #fff;
      width: 500px; 
      margin: 65px auto;
      border-color: #fff;
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
              flex-direction: column;
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;;
      }

      .user-container{
        background: #fff;
      width: 500px; 
      height: 500px;
      margin: 65px auto;
      border-color: #fff;
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
              flex-direction: column;
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;;
      }

    .container_register {
        background: #fff;
        width: 500px;
        margin: 65px auto;
        height:auto;
        border-color: #fff;
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
                flex-direction: column;
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;;
        }

  .padding {
      padding: 3rem;
      border-collapse: collapse;  
  }
      
  .label{
      text-align: left;
  }

  .textbox{
      border-radius: 6px;
      font-size: 12px;
      background: #fbfbfb;
      border: 2px solid transparent;
      height: 36px;
      box-shadow: 0 0 0 1px #dddddd, 0 2px 4px 0 rgb(0 0 0 / 7%), 0 1px 1.5px 0 rgb(0 0 0 / 5%);
      width: 100%;   
      margin: 8px 0;  
      padding: 12px 20px;   
      display: inline-block;   
      border: 2px solid rgb(0, 0, 0);   
      box-sizing: border-box;  
  
  }
