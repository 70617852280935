@import url("https://fonts.googleapis.com/css2?family=M+PLUS+1+Code:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");

.App {
  text-align: center;
}

html,
body,
#app,
#app > div {
  background-color: #0f2d52e0;

  align-content: center;
  margin: 0px;
}

/* Header CSS start */
.Nav {
  background: #235f83;
  height: 80px;
  display: flex;
  justify-content: space-between;
  /* padding: 0.5rem calc((100vw - 1000px) / 2); */
  z-index: 10;
  font-size: larger;
  -webkit-box-shadow: 0px -3px 17px -2px rgba(0, 0, 0, 0.7);
  box-shadow: 0px -3px 17px -2px rgba(0, 0, 0, 0.7);
}

.NavLink {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
}

.NavMenu {
  display: flex;
  align-items: center;
  margin-right: -24px;
}

.NavBtn {
  display: flex;
  align-items: center;
  margin-right: 24px;
}

.NavBtnLink {
  border-radius: 4px;
  background-color: black;
  padding: 10px 22px;
  color: #fff;
  outline: none;
  border: 1px solid black;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;


  padding: 10px;
  margin: 10px;

}

.logo {
  font-family: "Lobster", cursive;
  font-size: x-large;
  color: "#D5EEBB";
}
/* Header CSS end */

/* Footer CSS start */
.pagefooter {
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  width: 100%;
  /* Height of the footer*/
  height: 80px;
  align-content: center;
}

.socialmedias {
  display: inline-flex;
  align-items: center;
  justify-content: left;
  margin: 0;
}

.yt,
.fb,
.insta,
.twit {
  color: rgb(255, 255, 255);
  padding: 20px;
}
/* Footer CSS end */

/* image display CSS start */
.dis {
  display: inline-flex;
  flex-wrap: wrap;
  overflow: hidden;
  align-content: left;
  padding-top: 3px;
}

.image-container {
  position: relative;
  transition: transform 0.2s;
}

.image-container .imagetitle {
  word-wrap: break-word;
}

.image-container :hover {
  cursor: pointer;
  transform: scale(1.1);
}

.movieposter {
  padding: 2px;
  width: 200px;
  height: 273px;
  padding-bottom: 0;
}

.container {
  margin: 10px;
  align-items: center;
}
/* image display CSS end */

/* Hero CSS start */
.slider {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 1200px;
  height: 600px;
  border-radius: 10px;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 32px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}

.slidetitle {
  background-color: white;
  margin: 3px;
  word-wrap: break-word;
}
/* Hero CSS start */

.left-align {
  
  font-size: xx-large;
  text-align: left;
  padding: 3px;
  font-weight: bolder;
  box-shadow: #000;
}

/* description page CSS start */
.descimg {
  width: 800px;
  height: 600px;
  border-radius: 10px;
  padding-right: 5px;
}

.descContainer {
  display: inline-flex;
}

.synopsis {
  font-family: "M PLUS 1 Code", sans-serif;
  font-size: x-large;
  text-align: left;
  padding-left: 2px;
}

.btn {
  padding: 10px;
  margin: 10px;
  display: inline-block;
  color: white;
  background-color: black;
  outline: none;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}
/* description page CSS end */

/* miscellaneous */
.trivia {
  display: inline-flex;
}
.triviacontent {
  height: 150px;
  padding: 3px;
}

